import React from 'react';
import ReactDOM from 'react-dom/client';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { BrowserRouter } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import { PersistGate } from 'redux-persist/integration/react'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './lib/store/configureStore';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
     <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
        <SpeedInsights />
        <Analytics />
          <App />
        </BrowserRouter>
      </PersistGate>
     </Provider>
  </React.StrictMode>
);

reportWebVitals();
