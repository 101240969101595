import hipaa from "../../assets/mwm/hipaa.png";
import seal from "../../assets/mwm/pharmacy_new.png";
import { forwardRef } from "react";

const Footer = forwardRef<HTMLDivElement, any>((props, ref) => {
  const legitScriptUrl = "https://www.legitscript.com/websites/?checker_keywords=modernweightloss.com";
  const imageUrl = `${window.location.protocol}//static.legitscript.com/seals/31572174.png`;

  return (
    <footer
      ref={ref}
      className="justify-between fixed bottom-0 w-full items-center md:px-6 xl:px-[5rem] 2xl:py-4 bg-[#FFFFFF] hidden md:flex"
    >
      <div className="flex justify-between flex-1 space-x-4">
        <div className="flex gap-6 flex-row items-center">
          <img src={seal} alt="PCAB Gold Seal" className="rounded-full sm:h-12 2xl:h-[4rem] w-auto" />
          <img src={hipaa} alt="HIPAA Seal" className="rounded-full sm:h-12 2xl:h-[4rem] w-auto" />
          <a href={legitScriptUrl} target="_blank" title="Verify LegitScript Approval" rel="noopener noreferrer">
            <img
              src={imageUrl}
              alt="LegitScript approved"
              className="sm:h-12 2xl:h-[4rem] w-auto rounded-full"
            />
          </a>
        </div>
        <h1 className="text-[#333333] 2xl:text-2xl font-normal">
          The <span className="font-bold">Modern Weight Loss </span>
          <span className="text-[#008080] font-turbinado font-[500] text-[2.5rem]">
            Difference
          </span>
        </h1>
      </div>
    </footer>
  );
});

export default Footer;