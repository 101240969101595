import React, { Suspense, useEffect } from 'react';
import {  Route, Routes, useLocation } from 'react-router-dom';

export const RoutePath = {
  home: '/',
  glp: '/glp',
  info: '/info',
  greetings: '/greetings',
  // stateOfResidence: '/stateofresidence',
  greatNews: '/greatnews',
  BMICalculator: '/bmi',
  currentMedications: '/currentconditions',
  medicalHistory: '/medicalhistory',
  organHealth: '/organhealth',
  medicationsInput: '/medicationsinput',
  allergyinput: '/allergiesinput',
  thanksForSharing: '/thanks',
  initiatives: '/initiatives',
  weUnderstand: '/weunderstand',
  glp1Question: '/glp1question',
  pregnancy: '/pregnancy',
  motivation: '/motivation',
  weHearYou: '/wehearyou',
  idProofUpload: '/idproofupload',
  consent: '/consent',
  qualified: '/qualified',
  disqualified: '/disqualified',
  success: '/success',
};

interface RouteDefinition {
  path: string;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  importFunc: () => Promise<{ default: React.ComponentType<any> }>;
  nextRoute?: string;
}

const routeDefinitions: RouteDefinition[] = [
  {
    path: RoutePath.home,
    component: React.lazy(() => import('./pages/Home')),
    importFunc: () => import('./pages/Home'),
    nextRoute: RoutePath.glp,
  },
  {
    path: RoutePath.glp,
    component: React.lazy(() => import('./pages/GPL')),
    importFunc: () => import('./pages/GPL'),
    nextRoute: RoutePath.info,
  },
  {
    path: RoutePath.info,
    component: React.lazy(() => import('./pages/Info')),
    importFunc: () => import('./pages/Info'),
    nextRoute: RoutePath.greetings,
  },
  {
    path: RoutePath.greetings,
    component: React.lazy(() => import('./pages/Greetings')),
    importFunc: () => import('./pages/Greetings'),
    nextRoute: RoutePath.BMICalculator,
  },
  // {
  //   path: RoutePath.stateOfResidence,
  //   component: React.lazy(() => import('./pages/StateOfResidence')),
  //   importFunc: () => import('./pages/StateOfResidence'),
  //   nextRoute: RoutePath.BMICalculator,
  // },
  {
    path: RoutePath.BMICalculator,
    component: React.lazy(() => import('./pages/BMICalculator')),
    importFunc: () => import('./pages/BMICalculator'),
    nextRoute: RoutePath.currentMedications,
  },
  {
    path: RoutePath.greatNews,
    component: React.lazy(() => import('./pages/GreatNews')),
    importFunc: () => import('./pages/GreatNews'),
    nextRoute: RoutePath.currentMedications,
  },
  {
    path: RoutePath.currentMedications,
    component: React.lazy(() => import('./pages/CurrentConditions')),
    importFunc: () => import('./pages/CurrentConditions'),
    nextRoute: RoutePath.glp1Question,
  },
  {
    path: RoutePath.medicalHistory,
    component: React.lazy(() => import('./pages/MedicalHistory')),
    importFunc: () => import('./pages/MedicalHistory'),
    nextRoute: RoutePath.organHealth,
  },
  {
    path: RoutePath.organHealth,
    component: React.lazy(() => import('./pages/OrganHealth')),
    importFunc: () => import('./pages/OrganHealth'),
    nextRoute: RoutePath.glp1Question,
  },
  {
    path: RoutePath.glp1Question,
    component: React.lazy(() => import('./pages/Glp1Question')),
    importFunc: () => import('./pages/Glp1Question'),
    nextRoute: RoutePath.medicationsInput,
  },
  {
    path: RoutePath.medicationsInput,
    component: React.lazy(() => import('./pages/MedicationsInput')),
    importFunc: () => import('./pages/MedicationsInput'),
    nextRoute: RoutePath.allergyinput,
  },
  {
    path: RoutePath.allergyinput,
    component: React.lazy(() => import('./pages/AllergiesInput')),
    importFunc: () => import('./pages/AllergiesInput'),
    nextRoute: RoutePath.pregnancy,
  },
  {
    path: RoutePath.pregnancy,
    component: React.lazy(() => import('./pages/Pregnancy')),
    importFunc: () => import('./pages/Pregnancy'),
    nextRoute: RoutePath.thanksForSharing,
  },
  {
    path: RoutePath.thanksForSharing,
    component: React.lazy(() => import('./pages/ThanksForSharing')),
    importFunc: () => import('./pages/ThanksForSharing'),
    nextRoute: RoutePath.initiatives,
  },
  {
    path: RoutePath.initiatives,
    component: React.lazy(() => import('./pages/Initiatives')),
    importFunc: () => import('./pages/Initiatives'),
    nextRoute: RoutePath.weUnderstand,
  },
  {
    path: RoutePath.weUnderstand,
    component: React.lazy(() => import('./pages/WeUnderstand')),
    importFunc: () => import('./pages/WeUnderstand'),
    nextRoute: RoutePath.motivation,
  },
  {
    path: RoutePath.motivation,
    component: React.lazy(() => import('./pages/Motivation')),
    importFunc: () => import('./pages/Motivation'),
    nextRoute: RoutePath.weHearYou,
  },
  {
    path: RoutePath.weHearYou,
    component: React.lazy(() => import('./pages/WeHearYou')),
    importFunc: () => import('./pages/WeHearYou'),
    nextRoute: RoutePath.idProofUpload,
  },
  {
    path: RoutePath.idProofUpload,
    component: React.lazy(() => import('./pages/IdProofUpload')),
    importFunc: () => import('./pages/IdProofUpload'),
    nextRoute: RoutePath.consent,
  },
  {
    path: RoutePath.consent,
    component: React.lazy(() => import('./pages/Consent')),
    importFunc: () => import('./pages/Consent'),
    nextRoute: RoutePath.qualified,
  },
  {
    path: RoutePath.qualified,
    component: React.lazy(() => import('./pages/Qualified')),
    importFunc: () => import('./pages/Qualified'),
    nextRoute: RoutePath.success,
  },
  {
    path: RoutePath.disqualified,
    component: React.lazy(() => import('./pages/DisQualified')),
    importFunc: () => import('./pages/DisQualified'),
    nextRoute: RoutePath.success,
  },
  {
    path: RoutePath.success,
    component: React.lazy(() => import('./pages/Success')),
    importFunc: () => import('./pages/Success'),
  },
  // Default route for unmatched paths
  {
    path: '*',
    component: React.lazy(() => import('./pages/Home')),
    importFunc: () => import('./pages/Home'),
  },
];

interface AppRoutesProps {
  availableHeight: number;
  marginTop: number;
  availableWidth: number;
}

export const AppRoutes: React.FC<AppRoutesProps> = ({ availableHeight, marginTop, availableWidth }) => {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = routeDefinitions.find(route => route.path === location.pathname);
    if (currentRoute && currentRoute.nextRoute) {
      const nextRoute = routeDefinitions.find(route => route.path === currentRoute.nextRoute);
      if (nextRoute && nextRoute.importFunc) {
        nextRoute.importFunc(); 
      }
    }
  }, [location.pathname]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routeDefinitions.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              React.createElement(route.component, {
                availableHeight,
                marginTop,
                availableWidth,
              })
            }
          />
        ))}
      </Routes>
    </Suspense>
  );
};