import { useEffect } from 'react';

function useKeyboardAdjust() {
  useEffect(() => {
    const adjustHeight = () => {
      document.body.style.height = `${window.innerHeight}px`;
    };

    // Adjust height on focusin and focusout (when keyboard appears and disappears)
    window.addEventListener('focusin', adjustHeight);
    window.addEventListener('focusout', adjustHeight);
    window.addEventListener('resize', adjustHeight);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('focusin', adjustHeight);
      window.removeEventListener('focusout', adjustHeight);
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);
}

export default useKeyboardAdjust;